import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

import { Container } from '@/components/container';

import { ROUTES } from '@/constants/routes.constant';

import styles from './Layout.module.scss';

export const cabinetNavigation = [
  {
    name: 'Профиль',
    link: ROUTES.CABINET.PROFILE,
  },
  {
    name: 'Мои заказы',
    link: ROUTES.CABINET.ORDERS,
  },
  {
    name: 'Азалия Коины',
    link: ROUTES.CABINET.BONUSES,
  },
  {
    name: 'Акции 🔥',
    link: ROUTES.CABINET.PROMOTIONS,
  },
];

type ILayoutCabinet = {
  heading: string;
  customerPage?: boolean;
};

export default function Layout({
  heading,
  children,
  customerPage = false,
}: PropsWithChildren<ILayoutCabinet>) {
  const router = useRouter();

  return (
    <>
      <Container variant="sm">
        {customerPage ? (
          <>
            <div className={styles.mobileHeading_block}>
              <h2 className={styles.heading}>{heading}</h2>
            </div>
            <h2 className={styles.mobile_heading}>{heading}</h2>
          </>
        ) : (
          <aside className={styles.aside}>
            <h2 className={styles.heading}>Личный кабинет</h2>
            <h2 className={styles.mobile_heading}>{heading}</h2>
            <ul className={styles.aside_navigation}>
              {cabinetNavigation.map((el) => (
                <Link href={el.link} key={el.link}>
                  <button
                    className={
                      router.pathname === el.link
                        ? clsx(styles.aside_navigation__item, styles.aside_navigation__item_active)
                        : styles.aside_navigation__item
                    }
                  >
                    {el.name}
                  </button>
                </Link>
              ))}
            </ul>
          </aside>
        )}
        <section className={styles.wrapper}>{children}</section>
      </Container>
    </>
  );
}
