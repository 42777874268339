import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { BreadCrumbs } from '@/components/breadcrumbs';
import { Container } from '@/components/container';
import { MainLayout } from '@/components/layout/main-layout';
import { Pagination } from '@/components/pagination';
import { ProductsGrid } from '@/components/products-grid';
import { useRouterParams } from '@/components/sort-buttons/filters';
import { SortingSelect } from '@/components/sort-buttons/sorting-select';
import { sortingOptions } from '@/components/sort-buttons/sorting-select/sort-options.constant';
import ProductCardSkeleton from '@/components/ui/loaders/Skeletons/ProductCardSkeleton/ProductCardSkeleton';

import { analyticMetric } from '@/helpers/analytics-metric';
import { getSortParam } from '@/helpers/getSortParam';

import { useIgnoreUndefinedAndFirstAffect } from '@/hooks/useIgnoreUndefinedAndFirstAffect';
import { usePageState } from '@/hooks/usePageState';

import { ProductService } from '@/services/product.service';

import { OptionType } from '@/store/slices/sorting-products/sorting-products.slice';

import { IProductNew } from '@/types/new/products';

import { ROUTES } from '@/constants/routes.constant';

import { RubricPageProps } from '../../../pages/rubric/[slug]';

import styles from './Rubric.module.scss';

export const DEFAULT_LIMIT = 10;

export const Rubric = ({ title, rubricSlug, seoMarkdown, rubricData }: RubricPageProps) => {
  const [items, setItems] = useState<IProductNew[]>(rubricData.data);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [total, setTotal] = useState(rubricData.total);
  const router = useRouter();
  const { setParam } = useRouterParams({ shallow: true });
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const productPerPage = DEFAULT_LIMIT;
  const [page, setPage] = usePageState(total, productPerPage);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const goToPageRef = useRef<(page?: number) => void>();
  const sort = getSortParam(router.asPath);

  const searchHash = useIgnoreUndefinedAndFirstAffect(`${router.query.slug}#${sort}`);

  const loadMore = useCallback(async () => {
    if (isLoading || total === (page - 1) * productPerPage + items.length) return;
    const nextPage = page + 1;
    const skip = productPerPage * page;

    try {
      setIsLoading(true);
      const { data } = await ProductService.getByRubricSlug(
        String(router.query.slug),
        skip,
        productPerPage,
        sort
      );

      setItems([...items, ...data.items]);
      setPage(nextPage);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [isLoading, items, router.query.slug, sort, total, page]);

  function onSortSelect(item: OptionType) {
    setParam('sort', item.value);
  }

  const goToPage = (goToPageRef.current = useCallback(
    async (nextPage?: number) => {
      if (nextPage !== undefined && (nextPage < 1 || nextPage > Math.ceil(total / productPerPage)))
        return;
      const realPage = nextPage ? nextPage : isFirstLoad ? page : 1;
      const skip = productPerPage * (realPage - 1);

      try {
        setIsFetching(true);

        const { data } = await ProductService.getByRubricSlug(
          String(router.query.slug),
          skip,
          productPerPage,
          sort
        );

        setPage(realPage);
        setIsFirstLoad(false);
        setItems(data.items);
        setTotal(data.total);
        setIsFetching(false);

        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } catch (e) {
        console.log(e);
      }
    },
    [router.query.slug, sort, total]
  ));

  useEffect(() => {
    if (page !== 1) {
      setParam('page', page);
    } else {
      setParam('page', undefined);
    }
  }, [page]);

  useEffect(() => {
    if (searchHash === undefined) {
      return;
    }

    goToPageRef.current?.();
  }, [searchHash]);

  useEffect(() => {
    const startPosition = items.length - DEFAULT_LIMIT < 0 ? 0 : items.length - DEFAULT_LIMIT;
    analyticMetric.viewingTheProductList(
      items.slice(startPosition),
      page * DEFAULT_LIMIT - DEFAULT_LIMIT + 1,
      title
    );
  }, [router.asPath]);

  return (
    <MainLayout>
      <Container>
        <BreadCrumbs
          items={[
            {
              name: 'Доставка цветов',
              href: '/',
            },
            {
              name: title,
              href: ROUTES.RUBRIC(rubricSlug),
            },
          ]}
          className={styles.breadcrumbs}
        />
        <div ref={scrollRef} className={styles.header}>
          <h1 className={styles.title}>{title ?? ''}</h1>
          <SortingSelect
            value={sortingOptions.find((option) => option.value === sort) ?? sortingOptions[0]}
            options={sortingOptions}
            className={styles.sorting}
            onOptionSelect={onSortSelect}
          />
        </div>
        <div className={styles.grid}>
          {isFetching ? (
            <div className={styles.skeletonGrid}>
              {[...new Array(DEFAULT_LIMIT)].map((_, i) => (
                <ProductCardSkeleton key={i} />
              ))}
            </div>
          ) : (
            <ProductsGrid
              products={items}
              isLoading={isLoading}
              analyticMetricEcommerceList={title}
            />
          )}
          {total > productPerPage && (
            <Pagination
              totalItems={total}
              itemsPerPage={productPerPage}
              currentPage={page}
              loadMore={loadMore}
              className={styles.pagination}
              onPageChange={goToPage}
            />
          )}
        </div>
        {seoMarkdown && <ReactMarkdown className="seoMarkdown">{seoMarkdown}</ReactMarkdown>}
      </Container>
    </MainLayout>
  );
};
