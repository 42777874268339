import { useRouter } from 'next/router';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { addStepToCompleted, changeFormStep } from '@/store/slices/basket-form/basket-form.slice';

import { MINIMAL_PAYMENT_PRICE } from '@/constants/common.constants';

export const useBasketNavigation = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isLowerWhenTablet = useMediaQuery('480px');
  const { products } = useTypedSelector((state) => state.checkoutProducts);

  // I use reduce again because I need the amount without promo code
  const totalSum = products.reduce(
    (acc, product) => acc + product.amount * product.product.price,
    0
  );

  const isTotalSumLowerWhenMinimal = totalSum < MINIMAL_PAYMENT_PRICE;
  const totalAndMinimalDifference = MINIMAL_PAYMENT_PRICE - totalSum;

  function changeStep(currentStep: number) {
    dispatch(addStepToCompleted(currentStep));

    dispatch(changeFormStep(currentStep + 1));

    if (isLowerWhenTablet) {
      router.push(`#${currentStep}`, undefined, { shallow: true });
    }
  }

  return { changeStep, isTotalSumLowerWhenMinimal, totalAndMinimalDifference };
};
