import { useEffect, useRef, useState } from 'react';

export const useVisibleItems = (items: any, initialCount: number, threshold = 0.1) => {
  const [visibleItems, setVisibleItems] = useState(items.slice(0, initialCount));
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleItems(items);
            observer.disconnect();
          }
        });
      },
      { threshold }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [items, threshold]);

  return { visibleItems, containerRef };
};
