import { useEffect, useRef, useState } from 'react';

export function useIgnoreUndefinedAndFirstAffect<T>(val: T): T | undefined {
  const isAffectedRef = useRef(false);
  const [value, setValue] = useState<T>();

  useEffect(() => {
    if (val === undefined) {
      return;
    }

    if (!isAffectedRef.current) {
      isAffectedRef.current = true;
      return;
    }

    setValue(val);
  }, [val]);

  return value;
}
