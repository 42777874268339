import { useRouter } from 'next/router';

type RemoveParamFuncType = (exceptParam: string | null) => string | undefined;
export const useRemoveGetParamsExcept = (): {
  removeGetParamsExcept: RemoveParamFuncType;
} => {
  const router = useRouter();
  const removeGetParamsExcept: RemoveParamFuncType = (exceptParam) => {
    const url = decodeURIComponent(
      `https://azalianow.ru` + (router.asPath === '/' ? '' : router.asPath)
    );

    // Разбиваем URL на базовый путь и параметры
    const [baseUrl, paramsString] = url.split('?');

    //Если URL заканчивается на вопросительный знак и нет никаких параметров, то возвращаем URL с обрезанным вопросительным знаком
    if (!paramsString && url.endsWith('?')) {
      return url.slice(0, url.length - 1);
    }

    if (!paramsString && url.endsWith(']')) {
      return 'https://azalianow.ru';
    }

    if (url.includes('&&')) {
      return url.replace('&&', '&');
    }

    if (!paramsString) {
      return undefined; // Нет GET-параметров в URL, выходим из функции
    }

    // Разбиваем параметры на массив пар "ключ-значение"
    const params = paramsString.split('&');

    // Фильтруем параметры, убирая те, которые не равны exceptParam
    const filteredParams = params.filter((param) => {
      const [key, value] = param.split('=');
      return key === exceptParam;
    });

    if (!filteredParams.length) {
      return baseUrl; // Нет нужного GET-параметра, возвращаем базовый URL
    }

    // Собираем отфильтрованные параметры в строку
    const newParamsString = filteredParams.join('&');

    // Собираем новый URL из базового пути и новых параметров
    return `${baseUrl}?${newParamsString}`;
  };

  return { removeGetParamsExcept };
};
