import { YANDEX_METRIKA_PRODUCT_CLICK_CONTEXT } from '@/constants/common.constants';

type IProductContext = null | {
  list: string;
  position: number;
};

export const useProductCardSaveClickContext = () => {
  const saveContext = ({ list, position }: NonNullable<IProductContext>) => {
    try {
      localStorage.setItem(
        YANDEX_METRIKA_PRODUCT_CLICK_CONTEXT,
        JSON.stringify({ list, position })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const clearContext = () => {
    try {
      localStorage.removeItem(YANDEX_METRIKA_PRODUCT_CLICK_CONTEXT);
    } catch (e) {
      console.error(e);
    }
  };

  const getContext = (): IProductContext => {
    try {
      return JSON.parse(localStorage.getItem(YANDEX_METRIKA_PRODUCT_CLICK_CONTEXT) ?? '{}');
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return {
    clearContext,
    getContext,
    saveContext,
  };
};
