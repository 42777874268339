import qs from 'qs';

import { newBackendHttp, newBackendHttpV2 } from '@/api/instances/rest';

import { IEditPhoneResponse, IUniversalResponse } from '@/types/new/backend-responses';
import {
  IBurnableCoins,
  ICustomerNew,
  ICustomerOrder,
  ICustomerOrderWithProducts,
} from '@/types/new/customers';

interface UpdateDto {
  name: string;
  email: string;
  phone: string;
  birthday: string;
  gender: string;
  sms_mailing: boolean;
  profile_picture: {};
}

type TGetCustomerOrders = IUniversalResponse<{
  total: number;
  items: ICustomerOrderWithProducts[];
}>;

type TSortBy = keyof Omit<ICustomerOrder, 'note'>;

type TSort = 'asc' | 'desc';

export class CustomerService {
  static async getById(
    customerId: number,
    accessToken?: string
  ): Promise<IUniversalResponse<ICustomerNew>> {
    const { data } = await newBackendHttp.get<IUniversalResponse<ICustomerNew>>(
      `/customers/${customerId}`,
      {
        ...(accessToken
          ? {
              headers: {
                Cookie: `accessToken=${accessToken}`,
              },
            }
          : {}),
      }
    );

    return data;
  }

  static async update(
    customerId: number,
    body: Partial<UpdateDto>,
    accessToken?: string
  ): Promise<IUniversalResponse<ICustomerNew>> {
    const { data } = await newBackendHttpV2.put<IUniversalResponse<ICustomerNew>>(
      `/customers/${customerId}`,
      { ...body },
      {
        ...(accessToken
          ? {
              headers: {
                Cookie: `accessToken=${accessToken}`,
              },
            }
          : {}),
      }
    );

    return data;
  }

  static async sendCodeToNewPhone(
    customerId: number,
    phone: string
  ): Promise<IEditPhoneResponse<{ message: string }>> {
    const { data } = await newBackendHttp.post<IEditPhoneResponse<{ message: string }>>(
      `/customers/${customerId}/edit-phone`,
      { phone }
    );

    return data;
  }

  static async editPhone(
    customerId: number,
    phone: string,
    code: string
  ): Promise<IEditPhoneResponse<{ message: string }>> {
    const { data } = await newBackendHttp.put<IEditPhoneResponse<{ message: string }>>(
      `/customers/${customerId}/edit-phone-complete`,
      {
        phone,
        code,
      }
    );

    return data;
  }

  static async getAllOrders(
    withProducts?: boolean,
    skip = 0,
    take = 12,
    sortBy: TSortBy = 'created_at',
    sort: TSort = 'desc'
  ): Promise<TGetCustomerOrders> {
    const searchParams = qs.stringify(
      {
        skip,
        take,
        orderBy: { [sortBy]: sort },
        ...(withProducts ? { include: { orders_components: { item: true } } } : {}),
      },
      { encodeValuesOnly: true }
    );

    const { data } = await newBackendHttpV2.get<TGetCustomerOrders>(
      `/customers/orders?${searchParams}`
    );

    return data;
  }

  static async getCurrentCountOfBurnableCoins(): Promise<
    IUniversalResponse<{
      coins: number;
      burnableCoins: IBurnableCoins[];
    }>
  > {
    const { data } = await newBackendHttp.get<
      IUniversalResponse<{
        coins: number;
        burnableCoins: IBurnableCoins[];
      }>
    >(`/customers/burnable-coins`);

    return data;
  }
}
