import { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/router';
import { getPageParam } from '@/helpers/getPageParam';

export const usePageState = (total?: number, limit?: number): [number, Dispatch<SetStateAction<number>>] => {
  const router = useRouter();

  const [page, setPage] = useState<number>(() => {
    const page = getPageParam(router.asPath);

    if(total !== undefined && limit !== undefined){
      return page > Math.ceil(total / limit) ? 1 : page;
    }

    return page;
  });

  return [page, setPage];
}
