import { deleteCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getQueryParam } from '@/helpers/url/queryParams';

import { IOrderUtm } from '@/types/new/orders';

import { YANDEX_CLIENT_UTM_SETTINGS } from '@/constants/common.constants';

export const utmCookiesNames: (keyof IOrderUtm)[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

export const yandexUtmSources = ['split-lp', 'split-lk'];

export function useUtmSave() {
  const router = useRouter();

  useEffect(() => {
    const isUrlExistUtm = Object.keys(router.query).some((key) =>
      utmCookiesNames.includes(key as keyof IOrderUtm)
    );

    if (isUrlExistUtm) {
      utmCookiesNames.forEach((name) => deleteCookie(name));
    }

    if (yandexUtmSources.includes(router.query.utm_source as string)) {
      setCookie(YANDEX_CLIENT_UTM_SETTINGS.name, true, {
        maxAge: YANDEX_CLIENT_UTM_SETTINGS.maxAge,
      });
    }

    utmCookiesNames.forEach((name) => {
      const value = getQueryParam(router.asPath, name);
      if (!value) return;

      setCookie(name, value);
    });
  }, [router.query]);
}
