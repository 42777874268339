import { MutableRefObject, useEffect, useRef, useState } from 'react';

function useSectionVisible(
  rootMargin: string = '200px'
): [MutableRefObject<HTMLDivElement | null>, boolean] {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries, observerInstance) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observerInstance.unobserve(entry.target);
            }
          });
        },
        {
          root: null,
          rootMargin,
          threshold: 0,
        }
      );

      if (componentRef.current) {
        observer.observe(componentRef.current);
      }

      return () => {
        if (componentRef.current) {
          observer.unobserve(componentRef.current);
        }
      };
    } else {
      setIsVisible(true);
    }
  }, [rootMargin]);

  return [componentRef, isVisible];
}

export default useSectionVisible;
