import { useCallback, useEffect, useState } from 'react';

import { useHasMounted } from '@/hooks/useHasMounted';

export const useMediaQuery = (pixels: string, type: 'max-width' | 'min-width' = 'max-width') => {
  const hasMounted = useHasMounted();
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: any) => {
    if (e.matches) setTargetReached(true);
    else setTargetReached(false);
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(${type}: ${pixels})`);
    media.addEventListener('change', updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) setTargetReached(true);

    return () => media.removeEventListener('change', updateTarget);
  }, []);

  return targetReached && hasMounted;
};
